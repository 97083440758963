<template>
  <div class="list">
    <div class="list__item list__col-name list__item--border-none">
      <div class="col--1">
        <p class="list__col-name">Lp.</p>
      </div>
      <div class="col--2">
        <p class="list__col-name">ID</p>
      </div>
      <div class="col--3">
        <p class="list__col-name">Imię i nazwisko</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">Wiek</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">Kwota</p>
      </div>
      <div class="col--1">
        <p class="list__col-name">Akcje</p>
      </div>
    </div>
    <div class="list__item" v-for="(item, key) in items" :key="key">
      <div class="col--1">
        <p class="list__elem">{{ key + 1 }}.</p>
      </div>
      <div class="col--2">
        <p class="list__elem">{{ item.paradiso_id }}</p>
      </div>
      <div class="col--3">
        <p class="list__elem">{{ item.name }} {{ item.surname }}</p>
      </div>
      <div class="col--1">
        <p class="list__elem">{{ item.age }} lat</p>
      </div>
      <div class="col--1">
        <p class="list__elem list__elem--green">
          {{ item.price == null ? "30" : item.price }} zł
        </p>
      </div>
      <div class="col--1">
        <div class="list__icons">
          <div
            class="list__icon"
            @click="$emit('book', item)"
            v-tooltip="item.booked ? 'Zabookowany' : 'Bookuj'"
          >
            <!-- @click="$emit('book', { id: item.id, book: item.booked })" -->
            <svg
              :fill="item.booked ? '#95ec2f' : '#ffffff'"
              viewBox="0 -46 417.81333 417"
              width="417pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
              />
            </svg>
          </div>
          <div
            class="list__icon"
            @click="$emit('showMore', item)"
            v-tooltip="'Szczegóły'"
          >
            <img class="" src="@/assets/icons/more.svg" alt="" />
          </div>
          <div
            class="list__icon"
            v-tooltip="'Edytuj'"
            @click="$emit('edit', item)"
          >
            <img class="" src="@/assets/icons/edit.svg" alt="" />
          </div>
          <div
            class="list__icon"
            v-tooltip="'Usuń'"
            @click="$emit('delete', item)"
          >
            <img class="" src="@/assets/icons/delete.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    items: Array,
  },
  // name: 'HelloWorld',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

</style>
