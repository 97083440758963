<template>
  <div class="p-participant">
    <div class="paginate">
      <div class="paginate__item"></div>
    </div>
    <div>
      <Input
        class="form__row--col-2"
        placeholder="Szukaj"
        :value="test"
        @keyup.native.enter="searchEventUsers($event.target.value)"
      />
    </div>
    <!-- @return="searchEventUsers($event)" -->

    <List
      :items="eventUsers.data"
      @showMore="getDetails($event)"
      @edit="editDetails = $event"
      @delete="userToDelete = $event"
      @book="booingUser = $event"
    />
    <!-- @book="saveBookUser($event)" -->

    <!-- <Paginate
      :pages="eventUsers.pages"
      :currentPage="eventUsers.currentPage"
      @selected="fetchEventUsers($event)"
    /> -->

    <RightToolBar
      :showToolbar="details != null"
      v-on:closeToolbar="details = null"
    >
      <template v-slot:title>
        <div v-if="details">{{ details.name }} {{ details.surname }}</div>
      </template>
      <template v-slot:content>
        <div v-if="details">
          <div class="userDetails">
            <div>
              <p class="userDetails__title userDetails__title--first">
                Dane uczestnika
              </p>
              <p class="userDetails__item">ID: {{ details.paradiso_id }}</p>
              <p class="userDetails__item">PESEL: {{ details.pesel }}</p>
              <p class="userDetails__item">Telefon: {{ details.phone }}</p>
              <p class="userDetails__item">E-mail: {{ details.mail }}</p>
              <p class="userDetails__item" v-if="details.diet_id">
                Dieta: {{ registrationInfo.diets[details.diet_id] }}
              </p>
            </div>
            <div v-if="details.guardian_on_place">
              <p class="userDetails__title">Opiekun na miejscu</p>
              <p class="userDetails__item">
                Imię i nazwisko: {{ details.guardian_on_place.name }}
                {{ details.guardian_on_place.surname }}
              </p>
              <p class="userDetails__item">
                ID: {{ details.guardian_on_place.paradiso_id }}
              </p>
              <p class="userDetails__item">
                Telefon: {{ details.guardian_on_place.phone }}
              </p>
              <p class="userDetails__item">
                E-mail: {{ details.guardian_on_place.mail }}
              </p>
            </div>
            <div v-if="details.guardian_name">
              <p class="userDetails__title">Opiekun</p>
              <p class="userDetails__item">
                Imię i nazwisko: {{ details.guardian_name }}
              </p>
              <p class="userDetails__item">
                Telefon: {{ details.guardian_phone }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </RightToolBar>

    <RightToolBar
      :showToolbar="editDetails != null"
      v-on:closeToolbar="editDetails = null"
    >
      <template v-slot:title>
        <div v-if="editDetails">
          {{ editDetails.name }} {{ editDetails.surname }}
        </div>
      </template>
      <template v-slot:content>
        <div v-if="editDetails">
          <div class="userDetails">
            <div>
              <p class="userDetails__title userDetails__title--first">
                Dane uczestnika
              </p>
              <Input
                class="form__row--col-2"
                placeholder="ID"
                :value="editDetails.paradiso_id"
                @return="fieldToEditSave('paradiso_id', $event)"
              />
              <Input
                class="form__row--col-2"
                placeholder="PESEL"
                :value="editDetails.pesel"
                @return="fieldToEditSave('pesel', $event)"
              />
              <Input
                class="form__row--col-2"
                placeholder="Telefon"
                :value="editDetails.phone"
                @return="fieldToEditSave('phone', $event)"
              />
              <Input
                class="form__row--col-2"
                placeholder="E-mail"
                :value="editDetails.mail"
                @return="fieldToEditSave('mail', $event)"
              />
              <Input
                class="form__row--col-2"
                placeholder="Kwota"
                :value="editDetails.price"
                @return="fieldToEditSave('price', $event)"
              />
            </div>
            <p class="userDetails__title">Opiekun prawny</p>
            <Input
              class="form__row--col-2"
              placeholder="Imię i nazwisko"
              :value="editDetails.guardian_name"
              @return="fieldToEditSave('guardian_name', $event)"
            />
            <Input
              class="form__row--col-2"
              placeholder="Telefon"
              :value="editDetails.guardian_phone"
              @return="fieldToEditSave('guardian_phone', $event)"
            />
          </div>
          <Btn text="Zapisz" color="btn--green" @click.native="editSave()" />
          <Btn
            text="Anuluj"
            color="btn--red"
            @click.native="(editDetails = null), (dataToEditSave = {})"
          />
        </div>
      </template>
    </RightToolBar>
    <Popup
      :showPopup="userToDelete != null"
      v-on:closePopup="userToDelete = null"
    >
      <template v-slot:title>
        <div v-if="userToDelete">
          Czy chcesz usunąć uczestnika:
          <span>{{ userToDelete.name }} {{ userToDelete.surname }}</span
          >?
        </div>
      </template>
      <template v-slot:content>
        <Btn
          text="Nie"
          color="btn--green"
          @click.native="userToDelete = null"
        />
        <Btn
          text="Tak"
          color="btn--red"
          @click.native="
            deleteEventUsers(userToDelete.id), (userToDelete = null)
          "
        />
      </template>
    </Popup>

    <!-- platnosci -->
    <Popup :showPopup="booingUser != null" v-on:closePopup="booingUser = null">
      <template v-slot:title>
        <div>Bookowanie...</div>
      </template>
      <template v-slot:content>
        <div>
          <p class="userDetails__title">Płatność</p>
          <p class="userDetails__item" v-if="booingUser">
            Łączny koszt:
            {{ booingUser.price != null ? booingUser.price : "30zł" }}
          </p>
          <p class="userDetails__item" v-if="calcPayments() != true">
            Brakująca kwota:
            <span class="userDetails__item--red">{{ calcPayments() }}zł</span>
          </p>
          <p class="userDetails__item" v-else>
            Brakująca kwota:
            <span class="userDetails__item--green">Opłacono!</span>
          </p>
          <div class="userDetails__item" v-if="calcPayments() != true">
            <Btn
              :text="`Potwierdź przelew - ${calcPayments()}zł`"
              color="btn--green"
              @click.native="
                payTheRest(
                  booingUser,
                  1,
                  calcPayments(),
                  'Potwierdzenie przelewu'
                )
              "
            />
            <Btn
              :text="`Płatność gotówką - ${calcPayments()}zł`"
              color="btn--green"
              @click.native="
                payTheRest(
                  booingUser,
                  2,
                  calcPayments(),
                  'Płatność gotówką na miejscu'
                )
              "
            />
          </div>

          <Input
            class="form__row--col-2"
            placeholder="ID teczki"
            :value="folder_id"
            @return="folder_id = $event"
          />
          <Btn
            v-if="folder_id"
            text="Potwierdź"
            color="btn--green"
            @click.native="confirmBook(booingUser)"
          />
                 <Btn
            v-if="folder_id"
            text="Zamnij popup"
            color="btn--cancel"
            @click.native="booingUser = null"
          />
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import List from "@/components/lists/Participant-list.vue";
// import Paginate from "@/components/elements/Paginate.vue";
import Input from "@/components/elements/Input.vue";

export default {
  components: {
    List,
    // Paginate,
    Input,
  },
  data() {
    return {
      test: "",
      details: null,
      editDetails: null,
      userToDelete: null,
      booingUser: null,
      dataToEditSave: {},
      folder_id: null,
    };
  },
  computed: {
    ...mapGetters({
      eventUsers: "EventUsers/getEventUsers",
      registrationInfo: "EventUsers/getRegistrationInfo",
    }),
  },
  methods: {
    ...mapMutations({
      setcountFolders: "EventUsers/setcountFolders",
    }),
    ...mapActions({
      saveBookUser: "EventUsers/saveBookUser",
      fetchEventUsers: "EventUsers/fetchEventUsers",
      fetchRegistrationInfo: "EventUsers/fetchRegistrationInfo",
      deleteEventUsers: "EventUsers/deleteEventUsers",
      edit: "EventUsers/saveEdit",
      pay: "EventUsers/pay",
      searchEventUsers: "EventUsers/searchEventUsers",
    }),
    calcPayments() {
      let sum = 0;
      if (this.booingUser) {
        let userPrice = this.booingUser.price;
        if (this.booingUser.payments) {
          this.booingUser.payments.forEach((elem) => {
            sum = sum + elem.paid_in;
          });
        }
        if (userPrice == null) {
          userPrice = 30;
        }
        console.log(sum);
        if (sum >= parseInt(userPrice)) {
          return true;
        } else {
          return parseInt(userPrice) - sum;
        }
      }
    },
    async confirmBook(booingUser) {
      let obj = {
        id: booingUser.id,
        data: {
          booked: true,
          folder_id: this.folder_id,
        },
      };
      const response = await this.saveBookUser(obj);
      if (response.booked == "success") {
        this.setcountFolders(response.folderCount);
        this.$emit("clear", null);
      } else {
        this.setcountFolders(response.folderCount);
        this.$emit("clear", null);
      }
      // else {
      //   // if (response.user) {
      //   //   this.$emit("clear", null);
      //   //   this.$emit("message", 'Uczestnik już zabookowany');
      //   // }
      // }
      console.log(response);
    },
    getDetails(item) {
      this.details = item;
      this.details.guardian_on_place = this.eventUsers.data.find(
        (e) => e.id == item.guardian_id
      );
    },
    fieldToEditSave(field, value) {
      this.dataToEditSave[field] = value;
    },
    payTheRest(eventUser, type_id, paid_id, title) {
      console.log(eventUser);
      let obj = {
        event_user_id: eventUser.id,
        type_id: type_id, // 1 - przelew; 2 - gotowka
        paid_in: paid_id,
        title: title,
      };
      this.pay(obj);
    },
    async editSave() {
      this.dataToEditSave.id = this.editDetails.id;
      const response = await this.edit(this.dataToEditSave);
      if (response) {
        this.editDetails = null;
        this.dataToEditSave = {};
      }
    },
  },
  mounted() {
    this.fetchEventUsers();
    this.fetchRegistrationInfo();
  },
};
</script>
<style lang="scss" scoped>
.userDetails {
  &__item {
    margin-bottom: 1rem;
  }
  &__title {
    text-transform: uppercase;
    border-bottom: 1px solid $green;
    margin: 1.5rem -1rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2.5rem;
    &--first {
      margin-top: 0;
    }
  }
}
</style>
